<template>
  <div>
    <vx-card title="Account Receivable">
      <operating-unit-filter @onShow="onShow" />
      <vs-tabs :color="colorx">
        <vs-tab label="Payment Collection">
          <div class="tab-text">ini ya</div>
        </vs-tab>
        <vs-tab label="Payment Receive">
          <div class="tab-text">
            <payment-received />
          </div>
        </vs-tab>
        <vs-tab label="Waiting Reconcile">
          <div class="tab-text">ini ya</div>
        </vs-tab>
        <vs-tab label="Reconciled">
          <div class="tab-text">ini ya</div>
        </vs-tab>
        <vs-tab label="Waiting Reverse">
          <div class="tab-text">ini ya</div>
        </vs-tab>
        <vs-tab label="Reversed">
          <div class="tab-text">ini ya</div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>
import payment_received from "./payment-received/index.vue";
import operatingUnitFilter from "@/components/operatingUnitFilter/operatingUnitFilter.vue";

export default {
  components: {
    "payment-received": payment_received,
    "operating-unit-filter": operatingUnitFilter,
  },
  data: () => ({
    colorx: "danger",
    tabs: 0,
  }),
  async mounted() {
    await this.$store.dispatch("user/getPermissions", "Payment Receive");
  },
};
</script>
